import { Button } from '@mybridge/ui/button';
import { HStack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useContext } from 'react';
import { PostBoxContext } from '../../context';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const PostBoxPinPostModal = ({ ...props }) => {
  const { post, pinUnpinPost, pinUnpinPostLoading, pinned } =
    useContext(PostBoxContext);
  const { t } = useTranslator();

  const handlePinPost = () => {
    const payload = {
      id: post?.id,
    };
    pinUnpinPost(payload);
  };
  return (
    <>
      <Modal {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            {pinned ? t('otherModules.home_support') || 'Unpin' : t('otherModules.home_support') || 'Pin'} {t('otherModules.post') || "post"}?
          </ModalHeader>
          <ModalBody>
            <Text>
              {pinned
                ? t('otherModules.unpin_message') || 'This will no longer appear at the top of your profile.'
                : t('otherModules.pin_message') || 'This will appear at the top of your profile and replace any previously pinned post.'}
            </Text>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button onClick={props?.onClose} variant="secondary">
                {t('buttons.cancel') || "Cancel"}
              </Button>
              <Button
                isLoading={pinUnpinPostLoading}
                onClick={handlePinPost}
                variant="primary"
              >
                {pinned ? t('otherModules.home_support') || 'Unpin' : t('otherModules.home_support') || 'Pin'} {t('otherModules.now') || "Now"}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
