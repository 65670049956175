import { Button } from '@mybridge/ui/button';
import { Heading } from '@mybridge/ui/heading';
import { HStack, Stack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Radio, RadioGroup } from '@mybridge/ui/radio';
import { Text } from '@mybridge/ui/text';
import { useContext, useMemo, useState } from 'react';
import { getUserFullName } from 'v4/lib/commons';
import { PostBoxContext } from '../../context';
import { List, ListItem, UnorderedList } from '@mybridge/ui/list';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const PostBoxDeleteModal = ({ isFromProfile = false, ...props }) => {
  const { post, deletePost, deletePostLoading } = useContext(PostBoxContext);
  const { author } = post ?? {};
  const  { t } = useTranslator();

  const handleDeletePost = () => {
    const payload = {
      block_user_id: author?.id,
    };
    deletePost(payload);
    if (isFromProfile) {
      props?.onDeleteComplete();
    }
  };

  return (
    <>
      <Modal size="xl" {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            {t('otherModules.delete_post') || "Delete post?"}
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Stack>
                <Text>{t('otherModules.delete_thread_warning') || "This can't be undone and it will be removed from"}:</Text>
              </Stack>
              <UnorderedList>
                <Stack spacing={4}>
                  <ListItem>{t('otherModules.your_profile') || "Your profile"}</ListItem>
                  <ListItem>
                    {t('otherModules.followers_timeline') || "The timeline of any accounts that follow you"}
                  </ListItem>
                  <ListItem>{t('otherModules.mybridge_search_results') || "From myBridge search results"}</ListItem>
                </Stack>
              </UnorderedList>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button onClick={props?.onClose} variant="secondary">
                {t('buttons.cancel') || "Cancel"}
              </Button>
              <Button
                onClick={handleDeletePost}
                colorScheme="red"
                isLoading={deletePostLoading}
              >
                {t('profile.remove') || "Remove"}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
